// Generated by Framer (c747e62)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import AuthorTooltipIndicator from "./WdEbo2ZHT";
const AuthorTooltipIndicatorFonts = getFonts(AuthorTooltipIndicator);

const cycleOrder = ["SeqpYTtgF", "s9p0DmIhK", "wz5cse6vq", "XUinYQKkS", "qGELcLozO"];

const serializationHash = "framer-5cUXy"

const variantClassNames = {qGELcLozO: "framer-v-1kjkskm", s9p0DmIhK: "framer-v-nrs54d", SeqpYTtgF: "framer-v-1ymzggp", wz5cse6vq: "framer-v-q9s2jf", XUinYQKkS: "framer-v-1stejdy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Careers Green Anim Mob": "qGELcLozO", "Careers Green Anim": "wz5cse6vq", "Careers SVG Mob": "XUinYQKkS", "Careers SVG": "s9p0DmIhK", "Variant 1": "SeqpYTtgF"}

const getProps = ({careersSVG, height, id, width, ...props}) => { return {...props, q7PexMRYx: careersSVG ?? props.q7PexMRYx, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "SeqpYTtgF"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;careersSVG?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, q7PexMRYx, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "SeqpYTtgF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (["s9p0DmIhK", "XUinYQKkS"].includes(baseVariant)) return true;
return false
}

const isDisplayed1 = () => {
if (["s9p0DmIhK", "XUinYQKkS"].includes(baseVariant)) return false;
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ymzggp", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SeqpYTtgF"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({qGELcLozO: {"data-framer-name": "Careers Green Anim Mob"}, s9p0DmIhK: {"data-framer-name": "Careers SVG"}, wz5cse6vq: {"data-framer-name": "Careers Green Anim"}, XUinYQKkS: {"data-framer-name": "Careers SVG Mob"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<Image background={{alt: "", fit: "fill", ...toResponsiveImage(q7PexMRYx), ...{ positionX: "center", positionY: "center" }}} className={"framer-1enqxv3"} layoutDependency={layoutDependency} layoutId={"yRIP5f3Up"} {...addPropertyOverrides({s9p0DmIhK: {background: {alt: "", fit: "fill", sizes: "33px", ...toResponsiveImage(q7PexMRYx), ...{ positionX: "center", positionY: "center" }}}, XUinYQKkS: {background: {alt: "", fit: "fill", sizes: "33px", ...toResponsiveImage(q7PexMRYx), ...{ positionX: "center", positionY: "center" }}}}, baseVariant, gestureVariant)}/>)}{isDisplayed1() && (<ComponentViewportProvider width={"8px"} {...addPropertyOverrides({qGELcLozO: {width: "12px"}, wz5cse6vq: {width: "12px"}}, baseVariant, gestureVariant)}><motion.div className={"framer-19mdph6-container"} layoutDependency={layoutDependency} layoutId={"TtWmM6KT0-container"}><AuthorTooltipIndicator height={"100%"} id={"TtWmM6KT0"} layoutId={"TtWmM6KT0"} QbOKTnai0={"rgb(27, 198, 81)"} style={{height: "100%", width: "100%"}} variant={"cDS9_8JVf"} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5cUXy.framer-uhlbz, .framer-5cUXy .framer-uhlbz { display: block; }", ".framer-5cUXy.framer-1ymzggp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 200px; min-width: 200px; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-5cUXy .framer-1enqxv3 { flex: none; height: 29px; overflow: hidden; position: relative; width: 33px; }", ".framer-5cUXy .framer-19mdph6-container { aspect-ratio: 1 / 1; bottom: 1px; flex: none; height: var(--framer-aspect-ratio-supported, 8px); position: absolute; right: 1px; width: 8px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5cUXy.framer-1ymzggp { gap: 0px; } .framer-5cUXy.framer-1ymzggp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5cUXy.framer-1ymzggp > :first-child { margin-left: 0px; } .framer-5cUXy.framer-1ymzggp > :last-child { margin-right: 0px; } }", ".framer-5cUXy.framer-v-nrs54d.framer-1ymzggp { min-height: unset; min-width: unset; mix-blend-mode: difference; }", ".framer-5cUXy.framer-v-nrs54d .framer-1enqxv3 { mix-blend-mode: difference; }", ".framer-5cUXy.framer-v-q9s2jf.framer-1ymzggp, .framer-5cUXy.framer-v-1stejdy.framer-1ymzggp, .framer-5cUXy.framer-v-1kjkskm.framer-1ymzggp { min-height: unset; min-width: unset; }", ".framer-5cUXy.framer-v-q9s2jf .framer-19mdph6-container, .framer-5cUXy.framer-v-1kjkskm .framer-19mdph6-container { bottom: unset; height: var(--framer-aspect-ratio-supported, 12px); position: relative; right: unset; width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"s9p0DmIhK":{"layout":["auto","auto"]},"wz5cse6vq":{"layout":["auto","auto"]},"XUinYQKkS":{"layout":["auto","auto"]},"qGELcLozO":{"layout":["auto","auto"]}}}
 * @framerVariables {"q7PexMRYx":"careersSVG"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerL9aiZNpz7: React.ComponentType<Props> = withCSS(Component, css, "framer-5cUXy") as typeof Component;
export default FramerL9aiZNpz7;

FramerL9aiZNpz7.displayName = "Careers/Icon";

FramerL9aiZNpz7.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerL9aiZNpz7, {variant: {options: ["SeqpYTtgF", "s9p0DmIhK", "wz5cse6vq", "XUinYQKkS", "qGELcLozO"], optionTitles: ["Variant 1", "Careers SVG", "Careers Green Anim", "Careers SVG Mob", "Careers Green Anim Mob"], title: "Variant", type: ControlType.Enum}, q7PexMRYx: {title: "Careers SVG", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerL9aiZNpz7, [{explicitInter: true, fonts: []}, ...AuthorTooltipIndicatorFonts], {supportsExplicitInterCodegen: true})